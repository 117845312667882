import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <section class="bgimage" style={{ height: "300px" }}>
      <div class="container-fluid">
        <div class="row">
          {/* <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <br />
            <h2>Welcome</h2>
            <p style={{ color: "white" }}>
              Get Upto 50% off with fasbazar. This offer is a time limited, So
              be quick and get the amazing offer in time
            </p>
            <p>
              <a
                href="https://fasbazar.com/"
                class="btn btn-large"
                style={{ backgroundColor: "#FFDA27" }}
              >
                Visit Site »
              </a>
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;
