import LandingTop from "./components/landingTop/LandingTop";
import Cards from "./components/cards/Cards";
import Footer from "./components/footer/Footer";

import Hero from "./components/Hero/Hero";
import NewFooter from "./components/NewFooter/NewFooter";
import Navbar from "./components/navbar/Navbar";

function App() {
  return (
    <div>
      {/* <LandingTop /> */}

      <Hero />

      <Navbar />

      <Cards />

      <NewFooter />
    </div>
  );
}

export default App;
