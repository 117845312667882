export const ProductData = [
  {
    id: 1,
    offer: "9.1%",
    img:
      "https://fasbazar.com/wp-content/uploads/2022/01/Comfort-Spring-Dew-1-Ltr.jpg",
    tags: "Grocery, Comfort",
    title: "Comfort Spring Dew Fabric Softner",
    desc: "1 Ltr",
    oldPrice: "AED11.00",
    offerPrice: "AED10.00",
    link: "https://fasbazar.com/product/comfort-spring-dew-fabric-softner/"
  },

  {
    id: 2,
    offer: "5.7% ",
    img: "https://fasbazar.com/wp-content/uploads/2022/01/Untitled-1-19.jpg",
    tags: "Grocery, Ariel",
    title: "Ariel Downy Automatic Power Gel Laundry Detergent",
    desc: "2 Ltr",
    oldPrice: "AED35.00",
    offerPrice: "AED32.99",
    link:
      "https://fasbazar.com/product/ariel-downy-automatic-power-gel-laundry-detergent/"
  },
  {
    id: 3,
    offer: "42.9%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/837E2416-CEA1-4052-9BF4-2CD121C99D17.jpeg",
    tags: "Tag1, Tag2",
    title: "Genreic Leather Mini Wallet for Women",
    desc: "Fa, Health & Personal Care",
    oldPrice: "AED35.00",
    offerPrice: "AED19.99",
    link: "https://fasbazar.com/product/wallet-7/"
  },
  {
    id: 4,
    offer: "54.5%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/D3DA127A-150B-45D0-BDF1-3220A028A8F6.jpeg",
    tags: "Tag1, Tag2",
    title: "Gold glittery mini clutch",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!",
    oldPrice: "AED99.00",
    offerPrice: "AED45.00",
    link: "https://fasbazar.com/product/gold-glittery-mini-clutch/"
  },
  {
    id: 5,
    offer: "54.5%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/8A10181D-E6C7-4588-BEAE-8E9785636723.jpeg",
    tags: "Tag1, Tag2",
    title: "Silver Rectangle Clutch",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!",
    oldPrice: "AED99.00",
    offerPrice: "AED45.00",
    link: "https://fasbazar.com/product/silver-rectangle-clutch/"
  },
  {
    id: 6,
    offer: "42.9%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/18269CEE-2962-4ACC-96E1-054974FE6A06.jpeg",
    tags: "Tag1, Tag2",
    title: "Wallet",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!",
    oldPrice: "AED35.00",
    offerPrice: "AED19.99",
    link: "https://fasbazar.com/product/wallet/"
  },
  {
    id: 7,
    offer: "54.5%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/4CB3ADB7-458B-4695-BE06-D7A03CC7469C.jpeg",
    tags: "Tag1, Tag2",
    title: "Red Rectangle Clutch",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!",
    oldPrice: "AED99.00",
    offerPrice: "AED45.00",
    link: "https://fasbazar.com/product/red-rectangle-clutch/"
  },
  {
    id: 8,
    offer: "39.2%",
    img: "https://fasbazar.com/wp-content/uploads/2021/12/FA-6-pc.jpg",
    tags: "Tag1, Tag2",
    title: "Fa Refreshing Lemon Bar Soap, 175 gm, Pack of 6",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!",
    oldPrice: "AED19.75",
    offerPrice: "AED12.00",
    link:
      "https://fasbazar.com/product/fa-refreshing-lemon-bar-soap-175-gm-pack-of-6/"
  },
  {
    id: 9,
    offer: "59%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/BAE4178C-6B9D-49A9-9C6F-FA20093C0B94.jpeg",
    tags: "Tag1, Tag2",
    title: "Mini Black Leather Handbag",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!",
    oldPrice: "AED39.00",
    offerPrice: "AED15.99",
    link: "https://fasbazar.com/product/mini-black-handbag/"
  },
  {
    id: 10,
    offer: "52.6%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/4605AA4A-B863-459B-B96F-FFF3964E8788.jpeg",
    tags: "Tag1, Tag2",
    title: "Pink Crossbody Bag",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vero, possimus nostrum!",
    oldPrice: "AED95.00",
    offerPrice: "AED45.00",
    link: "https://fasbazar.com/product/pink-crossbody-bag/"
  },

  {
    id: 11,
    offer: "18.4%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/DD848618-5752-4A8F-8E48-E8ACEB984E6F.webp",
    title: "Emperio Armani Si – EDP 100ml Perfume+Body Lotion",
    oldPrice: "AED440.00",
    offerPrice: "AED359.00",
    link: "https://fasbazar.com/product/emperio-armani-si/"
  },
  {
    id: 12,
    offer: "36.2%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-29-at-9.55.08-AM.jpeg",
    title: "The One for Men – 100ml",
    oldPrice: "AED469.00",
    offerPrice: "AED299.00",
    link: "https://fasbazar.com/product/the-one-for-men-100ml/"
  },
  {
    id: 13,
    offer: "6.5%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-28-at-7.15.09-PM-1.jpeg",
    title: "Miss dior 50ml",
    oldPrice: "AED480.00",
    offerPrice: "AED449.00",
    link: "https://fasbazar.com/product/miss-dior-50ml/"
  },
  {
    id: 14,
    offer: "44.5%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-29-at-9.55.12-AM.jpeg",
    title: "Gucci bloom for her – EDP 100ml",
    oldPrice: "AED576.00",
    offerPrice: "AED319.75",
    link: "https://fasbazar.com/product/gucci-bloom-100ml-3/"
  },

  {
    id: 16,
    offer: "23.6%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-28-at-7.47.17-PM.jpeg",
    title: "Yves Saint Laurent Black Opium for her -EDP 90ml",
    oldPrice: "AED470.00",
    offerPrice: "AED359.00",
    link: "https://fasbazar.com/product/black-opium-90ml/"
  },
  {
    id: 17,
    offer: "28.8%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-28-at-8.32.48-PM.jpeg",
    title: "Viktor And Rolf flower bomb 100 ml for her",
    oldPrice: "AED560.00",
    offerPrice: "AED399.00",
    link: "https://fasbazar.com/product/viktor-and-rolf-flower-bomb-100-ml/"
  },
  {
    id: 18,
    offer: "27.9%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-28-at-7.15.12-PM.jpeg",
    title: "Emperio Armani Si 100ml",
    oldPrice: "AED555.00",
    offerPrice: "AED399.99",
    link: "https://fasbazar.com/product/emperio-armani-si-100ml-2/"
  },

  {
    id: 21,
    offer: "31.2%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-28-at-10.28.31-PM-1.jpeg",
    title: "Yves Saint Laurent Libre – 80ml",
    oldPrice: "AED580.00",
    offerPrice: "AED399.00",
    link: "https://fasbazar.com/product/saint-laurent-libre-80ml/"
  },
  {
    id: 22,
    offer: "25.6%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-28-at-7.15.06-PM.jpeg",
    title: "Givenchy l interdit for her 80 ml",
    oldPrice: "AED536.00",
    offerPrice: "AED399.00",
    link: "https://fasbazar.com/product/givenchy-l-interdit-for-her-80-ml/"
  },
  {
    id: 23,
    offer: "27.9%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-28-at-9.15.13-PM-1.jpeg",
    title: "La Vie Est belle 75 ml for her",
    oldPrice: "AED470.00",
    offerPrice: "AED339.00",
    link:
      "https://fasbazar.com/product/lancome-la-vie-est-belle-75-ml-for-her-2/"
  },
  {
    id: 24,
    offer: "29.3%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-29-at-10.40.14-AM.jpeg",
    title: "Robert Cavalli Paradiso for her – EDP 75ml",
    oldPrice: "AED149.99",
    offerPrice: "AED105.99",
    link: "https://fasbazar.com/product/robert-cavalli-paradiso-75ml-for-her-2/"
  },
  {
    id: 25,
    offer: "18.4%",
    img: "https://fasbazar.com/wp-content/uploads/2021/12/41wCCHhaMpL._AC_.jpg",
    title: "Colgate Max Fresh Cooling Crystal Cool Mint Gel Tooth Paste 100 ML",
    oldPrice: "AED9.50",
    offerPrice: "AED7.75",
    link:
      "https://fasbazar.com/product/colgate-max-fresh-cooling-crystal-cool-mint-gel-tooth-paste-100-ml/"
  },
  {
    id: 26,
    offer: "33.3%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/Rimmel-london-magnifeyes-eye-contouring-palette-spice.jpg",
    title: "Rimmel London Magnif’eyes Eye Contouring Palette Spice",
    oldPrice: "AED105.00",
    offerPrice: "AED69.99",
    link:
      "https://fasbazar.com/product/rimmel-london-magnifeyes-eye-contouring-palette-spice/"
  },

  {
    id: 28,
    offer: "33.4%",
    img: "https://fasbazar.com/wp-content/uploads/2021/12/Alokozay-green.jpg",
    title: "Alokozay Green Tea With Free Mug 100 Pieces",
    oldPrice: "AED15.00",
    offerPrice: "AED9.99",
    link:
      "https://fasbazar.com/product/alokozay-green-tea-with-free-mug-100-pieces/"
  },

  {
    id: 30,
    offer: "70.9%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-29-at-12.20.49-PM.jpeg",
    title: "Burberry Brit Sheer for Her – Edt 100ml",
    oldPrice: "AED350.00",
    offerPrice: "AED102.00",
    link: "https://fasbazar.com/product/burberry-brit-sheer-100ml/"
  },

  {
    id: 31,
    offer: "59.9%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/11/WhatsApp-Image-2021-11-28-at-7.15.10-PM.jpeg",
    title: "D&G The One Man – 100ml",
    oldPrice: "AED496.00",
    offerPrice: "AED199.00",
    link: "https://fasbazar.com/product/dg-the-one-man-100ml/"
  },
  {
    id: 32,
    offer: "59%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/D05A52B1-1EBB-42BE-BA26-C8A49AAA9E7A.jpeg",
    title: "Golden Mini Handbag",
    oldPrice: "AED39.00",
    offerPrice: "AED15.99",
    link: "https://fasbazar.com/product/mini-handbag/"
  },
  {
    id: 32,
    offer: "59%",
    img:
      "https://fasbazar.com/wp-content/uploads/2021/12/784AA517-F8C2-4CDB-9F31-D9BCA59C372A.jpeg",
    title: "Mini Black Leather Handbag",
    oldPrice: "AED39.00",
    offerPrice: "AED15.99",
    link: "https://fasbazar.com/product/black-mini-handbag/"
  }
  // {
  //   id: 33,
  //   offer: "57.5%",
  //   img:
  //     "https://fasbazar.com/wp-content/uploads/2021/12/Lip-Art-Graphic-Lipstick.jpg",
  //   title: "Rimmel Lip Art Graphic Liquid Lipstick",
  //   oldPrice: "AED47.00",
  //   offerPrice: "AED19.99",
  //   link: "https://fasbazar.com/product/rimmel-lip-art-graphic-liquid-lipstick/"
  // },
  // {
  //   id: 33,
  //   offer: "57.3%",
  //   img:
  //     "https://fasbazar.com/wp-content/uploads/2021/12/723F15A6-7403-4CFB-9C5C-6CDACD541DD6.jpeg",
  //   title: "Jadore by Christian Dior- For Women – EDP 100ml",
  //   oldPrice: "AED700.00",
  //   offerPrice: "AED299.00",
  //   link: "https://fasbazar.com/product/dior-jadore-100-ml-2/"
  // },
  // {
  //   id: 34,
  //   offer: "47.3%",
  //   img:
  //     "https://fasbazar.com/wp-content/uploads/2021/12/E8B868E3-E68E-44C3-A543-D0BBB699FB05.jpeg",
  //   title: "Mini gold glittery clutch",
  //   oldPrice: "AED150.00",
  //   offerPrice: "AED79.00",
  //   link: "https://fasbazar.com/product/mini-gold-glittery-clutch/"
  // }
];
