import React from "react";
import "bootstrap/dist/css/bootstrap.css";

const Navbar = () => {
  return (
    <nav
      class="navbar navbar-expand-md  navbar-light sticky-top"
      style={{ backgroundColor: "#C78CFF" }}
    >
      <a
        class="navbar-brand text-white"
        href="https://fasbazar.com/"
        style={{ fontWeight: "bolder", fontSize: "25px" }}
      >
        Fasbazar
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#collapsibleNavbar"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavbar">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link text-white" href="https://fasbazar.com/deals/">
              Deals
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              href="https://fasbazar.com/product-category/electronics/"
            >
              Electronics
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              href="https://fasbazar.com/product-category/fashion/"
            >
              Fashion
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              href="https://fasbazar.com/product-category/home/"
            >
              Home
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              href="https://fasbazar.com/product-category/grocery/"
            >
              Grocery
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              href="https://fasbazar.com/product-category/health-personal-care/"
            >
              Health & Personal Care
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              href="https://fasbazar.com/product-category/mobile-phones/"
            >
              Mobile Phones
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              href="https://fasbazar.com/product-category/perfumes-cosmetics/"
            >
              Perfumes & Cosmetics
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link text-white"
              href="https://fasbazar.com/my-account/"
            >
              My Account
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
