import React from "react";

const NewFooter = () => {
  return (
    <div
      class="container "
      style={{ marginLeft: "0px", backgroundColor: "#1c2331" }}
    >
      <footer
        class="text-center text-lg-start text-white"
        style={{
          backgroundColor: "#1c2331",
          width: "98vw",
          marginLeft: "0px"
        }}
      >
        <section
          class="d-flex justify-content-between p-4"
          style={{ backgroundColor: "#6351ce" }}
        >
          <div class="me-5">
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href="#" class="text-white me-4">
              <i class="fab fa-facebook-f" />
            </a>
            <a href="#" class="text-white me-4">
              <i class="fab fa-twitter" />
            </a>
            <a href="#" class="text-white me-4">
              <i class="fab fa-google" />
            </a>
            <a href="#" class="text-white me-4">
              <i class="fab fa-instagram" />
            </a>
            <a href="#" class="text-white me-4">
              <i class="fab fa-linkedin" />
            </a>
            <a href="#" class="text-white me-4">
              <i class="fab fa-github" />
            </a>
          </div>
        </section>

        <section class="">
          <div class="container text-center text-md-start mt-5">
            <div class="row mt-3">
              <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 class="text-uppercase fw-bold">Fasbazar.com</h6>
                <hr
                  class="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px"
                  }}
                />
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  Fasbazar is a marketplace of numerous vendors who are pride to
                  enjoy an easy selling with more profit. Get everything from
                  customized website to easy shipment at your fingertips.Your
                  dream online store is streamlined with a single click.
                </p>
              </div>

              <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 class="text-uppercase fw-bold">My Account</h6>
                <hr
                  class="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px"
                  }}
                />
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a href="https://fasbazar.com/my-account/" class="text-white">
                    My Account
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a href="https://fasbazar.com/cart/" class="text-white">
                    Cart
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a href="https://fasbazar.com/checkout/" class="text-white">
                    Checkout
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a href="https://fasbazar.com/shop/" class="text-white">
                    Shop
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a href="https://fasbazar.com/wishlist/" class="text-white">
                    Wishlist
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a href="https://fasbazar.com/contact-us/" class="text-white">
                    Contact US
                  </a>
                </p>
              </div>

              <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 class="text-uppercase fw-bold">Information</h6>
                <hr
                  class="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px"
                  }}
                />
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a
                    href="https://fasbazar.com/about-company/"
                    class="text-white"
                  >
                    About Company
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a
                    href="https://fasbazar.com/privacy-policy-2/"
                    class="text-white"
                  >
                    Privacy Policy
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a
                    href="https://fasbazar.com/terms-and-conditions/"
                    class="text-white"
                  >
                    Terms and Conditions
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a
                    href="https://fasbazar.com/delivery-policy/"
                    class="text-white"
                  >
                    Delivery Policy
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a
                    href="https://fasbazar.com/return-policy/"
                    class="text-white"
                  >
                    Return Policy
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <a href="https://fasbazar.com/warranties/" class="text-white">
                    Warranties
                  </a>
                </p>
              </div>

              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 class="text-uppercase fw-bold">CONTACT INFO</h6>
                <hr
                  class="mb-4 mt-0 d-inline-block mx-auto"
                  style={{
                    width: "60px",
                    backgroundColor: "#7c4dff",
                    height: "2px"
                  }}
                />
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <i class="fas fa-home mr-3" /> Sharjah saif zone
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <i class="fas fa-envelope mr-3" /> sales@fasbazar.com
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <i class="fas fa-phone mr-3" /> Ph: 800 6972
                </p>
                <p
                  style={{
                    fontSize: "13px"
                  }}
                >
                  <i class="fas fa-print mr-3" /> View on the Google Maps
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          class="text-center p-3"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)"
          }}
        >
          © 2022 Copyright:
          <a class="text-white" href="https://fasbazar.com/">
            fasbazar.com
          </a>
        </div>
      </footer>
    </div>
  );
};

export default NewFooter;
